import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@app/config/store';
import { FSLUG, MSLUG } from '@app/constant/features';

export function userAuth(): CanActivateFn {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (route, state) => {
    const store = inject(Store);
    const router: Router = inject(Router);

    // unauthorised check and redirect
    if (!store.authToken()) {
      router.navigate(['login']);
      return false;
    }

    return true;
  };
}

export function publicAuth(): CanActivateFn {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (route, state) => {
    const store = inject(Store);
    const router: Router = inject(Router);

    // unauthorised check and redirect
    if (store.authToken()) {
      router.navigate(['redirect']);
      return false;
    }

    return true;
  };
}

export function userAccessAuth(featureSlug: FSLUG): CanActivateFn {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (route, state) => {
    const store = inject(Store);
    const router: Router = inject(Router);
    if (!store.userAccess()[featureSlug]) {
      router.navigate(['/not-authorised']);
      return false;
    }
    return true;
  };
}

export function moduleAccess(moduleSlug: MSLUG): CanActivateFn {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (route, state) => {
    const store = inject(Store);
    const router: Router = inject(Router);

    if (!store.moduleAccess()[moduleSlug]) {
      router.navigate(['/not-authorised']);
      return false;
    }
    return true;
  };
}
